import { callAPI, sleep } from '@/common/commonAPI';
import store from '@/store';
import { computed } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  // {
  //   path: '/',
  //   redirect: '/test'
  // },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/TestPage.vue')
  },
  {
    path: '/admin',
    redirect: '/admin/booth'
  },
  /* booth */
  {
    path: '/admin/booth',
    name: 'BoothParent',
    component: () => import('../views/AdminParentPage.vue'),
    children: [
      {
        path: '/admin/booth',
        redirect: '/admin/booth/list'
      },
      {
        path: 'list',
        name: 'BoothList',
        component: () => import('../views/booth/BoothListPage.vue'),
      },
      {
        path: 'list_en',
        name: 'BoothListEn',
        component: () => import('../views/booth/BoothListEnPage.vue'),
      },
      {
        path: 'edit',
        name: 'BoothEdit',
        component: () => import('../views/booth/BoothEditPage.vue'),
      },
    ]
  },
  /* conference */
  {
    path: '/admin/conference',
    name: 'ConferenceParent',
    component: () => import('../views/AdminParentPage.vue'),
    children: [
      {
        path: '/admin/conference',
        redirect: '/admin/conference/list'
      },
      {
        path: 'list',
        name: 'ConferenceList',
        component: () => import('../views/conference/ConferenceListPage.vue'),
      },
      {
        path: 'list_en',
        name: 'ConferenceListEn',
        component: () => import('../views/conference/ConferenceListEnPage.vue'),
      },
      {
        path: 'edit',
        name: 'ConferenceEdit',
        component: () => import('../views/conference/ConferenceEditPage.vue'),
      },
    ]
  },
  /* news */
  {
    path: '/admin/news',
    name: 'NewsParent',
    component: () => import('../views/AdminParentPage.vue'),
    children: [
      {
        path: '/admin/news',
        redirect: '/admin/news/list'
      },
      {
        path: 'list',
        name: 'NewsList',
        component: () => import('../views/news/NewsListPage.vue'),
      },
      {
        path: 'list_en',
        name: 'NewsListEn',
        component: () => import('../views/news/NewsListEnPage.vue'),
      },
      {
        path: 'edit',
        name: 'NewsEdit',
        component: () => import('../views/news/NewsEditPage.vue'),
      },
    ]
  },
  /* event */
  {
    path: '/admin/event',
    name: 'EventParent',
    component: () => import('../views/AdminParentPage.vue'),
    children: [
      {
        path: '/admin/event',
        redirect: '/admin/event/list'
      },
      {
        path: 'event',
        name: 'EventList',
        component: () => import('../views/news/EventListPage.vue'),
      },
      {
        path: 'event_en',
        name: 'EventListEn',
        component: () => import('../views/news/EventListEnPage.vue'),
      },
      {
        path: 'edit',
        name: 'EventEdit',
        component: () => import('../views/news/EventEditPage.vue'),
      },
    ]
  },
  /* member */
  {
    path: '/admin/member',
    name: 'MemberParent',
    component: () => import('../views/AdminParentPage.vue'),
    children: [
      {
        path: '/admin/member',
        redirect: '/admin/member/attender_list'
      },
      {
        path: 'attender_list',
        name: 'AttenderList',
        component: () => import('../views/member/AttenderListPage.vue'),
      },
      {
        path: 'visitor_list',
        name: 'VisitorList',
        component: () => import('../views/member/VisitorListPage.vue'),
      },
      {
        path: 'org_list',
        name: 'OrgList',
        component: () => import('../views/member/OrgListPage.vue'),
      },
      {
        path: 'attender_edit',
        name: 'AttenderEdit',
        component: () => import('../views/member/AttenderEditPage.vue'),
      },
      {
        path: 'visitor_edit',
        name: 'VisitorEdit',
        component: () => import('../views/member/VisitorEditPage.vue'),
      },
      {
        path: 'org_edit',
        name: 'OrgEdit',
        component: () => import('../views/member/OrgEditPage.vue'),
      },
      {
        path: 'code_list/Type01',
        name: 'CodeListType01',
        component: () => import('../views/member/CodeListPage.vue'),
      },
      {
        path: 'code_list/Type02',
        name: 'CodeListType02',
        component: () => import('../views/member/CodeListPage.vue'),
      },
      {
        path: 'code_list/Type03',
        name: 'CodeListType03',
        component: () => import('../views/member/CodeListPage.vue'),
      },
      {
        path: 'code_list/Type04',
        name: 'CodeListType04',
        component: () => import('../views/member/CodeListPage.vue'),
      },
      {
        path: 'code_list/Type05',
        name: 'CodeListType05',
        component: () => import('../views/member/CodeListPage.vue'),
      },
      {
        path: 'code_list/Type06',
        name: 'CodeListType06',
        component: () => import('../views/member/CodeListPage.vue'),
      },
      {
        path: 'code_list/Type07',
        name: 'CodeListType07',
        component: () => import('../views/member/CodeListPage.vue'),
      },
      {
        path: 'code_list/Type08',
        name: 'CodeListType08',
        component: () => import('../views/member/CodeListPage.vue'),
      },
      {
        path: 'code_list/Type09',
        name: 'CodeListType09',
        component: () => import('../views/member/CodeListPage.vue'),
      },
      {
        path: 'code_list/Type10',
        name: 'CodeListType10',
        component: () => import('../views/member/CodeListPage.vue'),
      },
      {
        path: 'code_edit/:code',
        name: 'CodeEdit',
        component: () => import('../views/member/CodeEditPage.vue'),
      },
    ]
  },
  /* faq */
  {
    path: '/admin/faq',
    name: 'FaqParent',
    component: () => import('../views/AdminParentPage.vue'),
    children: [
      {
        path: '/admin/faq',
        redirect: '/admin/faq/list_l'
      },
      {
        path: 'list_large',
        name: 'FaqList1',
        component: () => import('../views/faq/FaqList1Page.vue'),
      },
      {
        path: 'list_medium',
        name: 'FaqList2',
        component: () => import('../views/faq/FaqList2Page.vue'),
      },
      {
        path: 'list_small',
        name: 'FaqList3',
        component: () => import('../views/faq/FaqList3Page.vue'),
      },
      {
        path: 'edit',
        name: 'FaqEdit',
        component: () => import('../views/faq/FaqEditPage.vue'),
      },
    ]
  },
  /* statistics */
  {
    path: '/admin/statistics',
    name: 'StatisticsParent',
    component: () => import('../views/AdminParentPage.vue'),
    children: [
      {
        path: '/admin/statistics',
        redirect: '/admin/statistics/list'
      },
      {
        path: 'list',
        name: 'StatisticsList',
        component: () => import('../views/statistics/StatisticsListPage.vue'),
      },
      // {
      //   path: 'view',
      //   name: 'StatisticsView',
      //   props: true,
      //   component: () => import('../views/statistics/StatisticsViewPage.vue'),
      // },
      {
        path: 'view/exhibition',
        name: 'StatisticsViewExhibition',
        component: () => import('../views/statistics/StatisticsViewExhibitionPage.vue'),
      },
      {
        path: 'view/booth',
        name: 'StatisticsViewBooth',
        component: () => import('../views/statistics/StatisticsViewBoothPage.vue'),
      },
      {
        path: 'view/nation',
        name: 'StatisticsViewNation',
        component: () => import('../views/statistics/StatisticsViewNationPage.vue'),
      },
      {
        path: 'view/member/user',
        name: 'StatisticsViewMember',
        component: () => import('../views/statistics/member/StatisticsViewMember0101Page.vue'),
      },
      {
        path: 'view/member/auth',
        name: 'StatisticsViewMember0102',
        component: () => import('../views/statistics/member/StatisticsViewMember0102Page.vue'),
      },
      {
        path: 'view/member/visit',
        name: 'StatisticsViewMember0201',
        component: () => import('../views/statistics/member/StatisticsViewMember0201Page.vue'),
      },
      {
        path: 'view/member/purpose',
        name: 'StatisticsViewMember0202',
        component: () => import('../views/statistics/member/StatisticsViewMember0202Page.vue'),
      },
      {
        path: 'view/member/recog',
        name: 'StatisticsViewMember0203',
        component: () => import('../views/statistics/member/StatisticsViewMember0203Page.vue'),
      },
      {
        path: 'view/member/connect',
        name: 'StatisticsViewMember0204',
        component: () => import('../views/statistics/member/StatisticsViewMember0204Page.vue'),
      },
      {
        path: 'view/member/interest',
        name: 'StatisticsViewMember0301',
        component: () => import('../views/statistics/member/StatisticsViewMember0301Page.vue'),
      },
      {
        path: 'view/member/occupa',
        name: 'StatisticsViewMember0302',
        component: () => import('../views/statistics/member/StatisticsViewMember0302Page.vue'),
      },
    ]
  },
  /* sign */
  {
    path: '/sign',
    name: 'Start',
    component: () => import('../views/sign/SignInStartPage.vue'),
  },
  {
    path: '/sign/signin',
    name: 'SignIn',
    component: () => import('../views/sign/SignInPage.vue'),
  },
  {
    path: '/sign/signup',
    name: 'SignUp',
    component: () => import('../views/sign/SignUpPage.vue'),
  },
  {
    path: '/sign/new_password',
    name: 'NewPassword',
    component: () => import('../views/sign/NewPasswordPage.vue'),
  },
  {
    path: '/sign/send_new_password',
    name: 'EmailNewPassword',
    component: () => import('../views/sign/EmailNewPasswordPage.vue'),
  },
  {
    path: '/sign/send_confirm',
    name: 'EmailConfirmSend',
    component: () => import('../views/sign/EmailConfirmSendPage.vue'),
  },
  {
    path: '/sign/send_confirm_need',
    name: 'EmailConfirmNeed',
    component: () => import('../views/sign/EmailConfirmNeedPage.vue'),
  },
  {
    path: '/sign/send_confirm_success',
    name: 'EmailConfirmSuccess',
    component: () => import('../views/sign/EmailConfirmSuccessPage.vue'),
  },
  {
    path: '/sign/finish',
    name: 'Finish',
    component: () => import('../views/sign/FinishPage.vue'),
  },
  /* host-center */
  {
    path: '/hc',
    name: 'HostCenterParent',
    component: () => import('../views/hostCenter/HostCenterParentPage.vue'),
    children: [
      {
        path: '/hc',
        redirect: '/hc/booth_info'
      },
      {
        path: 'booth_info',
        name: 'HCBooth01',
        component: () => import('../views/hostCenter/BoothDetail01Page.vue'),
      },
      {
        path: 'booth_contents',
        name: 'HCBooth02',
        component: () => import('../views/hostCenter/BoothDetail02Page.vue'),
      },
      {
        path: 'news',
        name: 'HCNewsList',
        component: () => import('../views/news/NewsListPage.vue'),
      },
      {
        path: 'news/edit',
        name: 'HCNewsEdit',
        component: () => import('../views/news/NewsEditPage.vue'),
      },
      {
        path: 'event',
        name: 'HCEventList',
        component: () => import('../views/news/EventListPage.vue'),
      },
      {
        path: 'event/edit',
        name: 'HCEventEdit',
        component: () => import('../views/news/EventEditPage.vue'),
      },
    ]
  },
  // will match everything and put it under `$route.params.pathMatch`
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/NotFoundPage.vue'),
  },

  {
    path: '/',
    name: 'FrontParentPage',
    redirect: '/main',
    component: () => import('../views/front/FrontParentPage.vue'),
    children: [
      /* main */
      {
        path: '/main',
        name: 'Main',
        component: () => import('../views/front/main/MainPage.vue'),
      },
      /* exhibition */
      {
        path: '/exhibition',
        name: 'ExhibitionList',
        component: () => import('../views/front/exhibition/BoothListPage.vue'),
        props: true,
      },
      {
        path: '/exhibition/booth2d',
        name: 'Exhibition2D',
        component: () => import('../views/front/exhibition/Booth2dPage.vue'),
        props: true,
      },
      {
        path: '/exhibition/booth3d',
        name: 'Exhibition3D',
        component: () => import('../views/front/exhibition/Booth3dPage.vue'),
      },
      /* about */
      {
        path: '/about',
        name: 'About',
        component: () => import('../views/front/about/AboutPage.vue'),
      },
      /* newsevent - front */
      {
        path: '/newsevent/event',
        name: 'FrontEventList',
        component: () => import('../views/front/news/EventListPage.vue'),
      },
      {
        path: '/newsevent/news',
        name: 'FrontNewsList',
        component: () => import('../views/front/news/NewsListPage.vue'),
      },
      {
        path: '/newsevent/news/view',
        name: 'FrontNewsView',
        component: () => import('../views/front/news/NewsViewPage.vue'),
        props: true,
      },
      {
        path: '/newsevent/event/view',
        name: 'FrontEventView',
        component: () => import('../views/front/news/EventViewPage.vue'),
        props: true,
      },
      /* partner - partner front */
      {
        path: '/partner',
        name: 'FrontPartnerList',
        component: () => import('../views/front/partner/PartnerListPage.vue'),
      },
      {
        path: '/partner/view',
        name: 'FrontPartnerView',
        component: () => import('../views/front/partner/PartnerViewPage.vue'),
      },
      /* partner - business front */
      {
        path: '/business',
        name: 'FrontBusinessList',
        component: () => import('../views/front/partner/BusinessListPage.vue'),
      },
      {
        path: '/business/view',
        name: 'FrontBusinessView',
        component: () => import('../views/front/partner/BusinessViewPage.vue'),
      },
      {
        path: "/export_roadmap_growth",
        name: "FrontExportRoadmapGrowthStage",
        component: () =>
            import("../views/front/partner/ExportRoadmapGrowthPage.vue"),
      },
      {
        path: "/export_roadmap_export",
        name: "FrontExportRoadmapExportStage",
        component: () =>
            import("../views/front/partner/ExportRoadmapExportPage.vue"),
      },
      {
        path: "/export_roadmap_export_detail/:no",
        name: "FrontExportRoadmapExportDetailStage",
        component: () =>
            import("../views/front/partner/ExportRoadmapExportDetailPage.vue"),
      },
      {
        path: "/export_roadmap",
        name: "FrontExportRoadmapList",
        component: () =>
            import("../views/front/partner/ExportRoadmapListPage.vue"),
      },
      {
        path: "/export_roadmap_faq",
        name: "FrontExportRoadmapFaqStage",
        component: () =>
            import("../views/front/partner/ExportRoadmapFaqPage.vue"),
      },

      /* mypage */
      {
        path: '/mypage',
        name: 'FrontMypageParent',
        component: () => import('../views/front/mypage/MypageParentPage.vue'),
        children: [
          {
            path: '/mypage',
            redirect: '/mypage/member'
          },
          {
            path: 'member_seller',
            name: 'FrontSellerMypageMember',
            component: () => import('../views/front/mypage/SellerMemberPage.vue'),
          },
          {
            path: 'member_buyer',
            name: 'FrontBuyerMypageMember',
            component: () => import('../views/front/mypage/BuyerMemberPage.vue'),
          },
          {
            path: 'exchange_seller',
            name: 'FrontSellerMypageExchange',
            component: () => import('../views/front/mypage/SellerExchangePage.vue'),
          },
          {
            path: 'exchange_buyer',
            name: 'FrontBuyerMypageExchange',
            component: () => import('../views/front/mypage/BuyerExchangePage.vue'),
          },
          {
            path: 'wishlist_buyer',
            name: 'FrontBuyerMypageWishList',
            component: () => import('../views/front/mypage/BuyerWishListPage.vue'),
          },
          {
            path: 'like_buyer',
            name: 'FrontBuyerMypageLikeMember',
            component: () => import('../views/front/mypage/BuyerLikeMemberListPage.vue'),
          },
          {
            path: 'wishlist_seller',
            name: 'FrontSellerMypageWishList',
            component: () => import('../views/front/mypage/SellerWishListPage.vue'),
          },
          {
            path: 'recommend_seller',
            name: 'FrontBuyerMypageRecommend',
            component: () => import('../views/front/mypage/BuyerRecommendSeller.vue'),
          },
          {
            path: 'recommend_buyer',
            name: 'FrontSellerMypageRecommend',
            component: () => import('../views/front/mypage/SellerRecommendBuyer.vue'),
          },
          {
            path: 'ask',
            name: 'FrontMypageAsk',
            component: () => import('../views/front/mypage/MypageAskPage.vue'),
          },
          {
            path: 'ask/view',
            name: 'FrontMypageAskView',
            component: () => import('../views/front/mypage/MypageAskViewPage.vue'),
          },
        ]
      },
    ]
  },
]

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(process.env.BASE_URL),
  routes, // short for `routes: routes`
})

router.beforeEach((to, from, next) => {

  //admin
  if( to.path.startsWith("/admin") ){
    ///1.로그인 체크
    const token = computed(() => store.getters.getAuth);
    if (token.value.accessToken == "") {
      alert("로그인이 필요합니다.");

      //메인으로
      store.dispatch("goMain");

      next(false);
    } else {
      //2. intGubun 체크
      // 90 이상 슈퍼관리자
      const userInfo = computed(() => store.getters.getUserInfo);
      if (JSON.stringify(userInfo.value) != "{}" && userInfo.value.intGubun >= 90) {

        next();
        return;

      } else {
        alert("슈퍼관리자만 접근이 가능합니다.");

        //메인으로
        store.dispatch("goMain");

        next(false);
        return;
      }
    }

    return;
  }
  //seller
  else if (to.path.startsWith("/hc")) {
    //1.로그인 체크
    const token = computed(() => store.getters.getAuth);
    if (token.value.accessToken == "") {
      alert("로그인이 필요합니다.");

      //메인으로
      store.dispatch("goMain");

      next(false);
      return;

    } else {
      //2. intGubun 체크
      // 10 이상 셀러
      const userInfo = computed(() => store.getters.getUserInfo);
      if (JSON.stringify(userInfo.value) != "{}" && userInfo.value.intGubun >= 10) {

        next();
        return;

      } else {
        alert("셀러만 접근이 가능합니다.");

        //메인으로
        store.dispatch("goMain");

        next(false);
        return;
      }
    }
  }
  else {
    next();
  }
})

export default router;